import { computed, override, toJS } from 'mobx';
import { stores } from '@strategies/stores';
import { ExtendedModel, model, modelAction, prop } from 'mobx-keystone';

import { formatDollars } from "../../../core/util";
import DashiProject, { IProjectData, TimelineStatus } from '../../../core/models/Project';
import DashiPeriod from "../../../core/models/Period";
import { KVP } from "../../../core/util";

export interface IFGProjectData extends IProjectData {
    use: string;
    beds: number;
    apts: number;
    classrooms: number;
    parking: number;
}

@model('fg/Project')
class FGProject extends ExtendedModel(DashiProject, {
    use: prop<string>().withSetter(),
    beds: prop<number>().withSetter(),
    apts: prop<number>().withSetter(),
    classrooms: prop<number>().withSetter(),
    parking: prop<number>().withSetter(),
}) {

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setType(snapshot.type);
            this.setUse(snapshot.use);
            this.setBeds(snapshot.beds);
            this.setApts(snapshot.apts);
            this.setClassrooms(snapshot.classrooms);
            this.setParking(snapshot.parking);
            this.setTimestamps(snapshot.timestamps);
        }
    }

    @override
    get cardInfo(): { label: string, value: string }[] {
        const { app } = stores;
        const info: { label: string, value: string }[] = [];

        if (app.colorMode !== 'use') {
            info.push({ label: 'Use', value: this.use });
        }
        if (app.colorMode !== 'type') {
            info.push({ label: 'Type', value: this.type });
        }
        if (this.beds !== 0) {
            info.push({ label: 'Beds', value: `${this.beds}` });
        }
        if (this.apts !== 0) {
            info.push({ label: 'Apartments', value: `${this.apts}` });
        }
        if (this.classrooms !== 0) {
            info.push({ label: 'Classrooms', value: `${this.classrooms}` });
        }
        if (this.parking !== 0) {
            info.push({ label: 'Parking', value: `${this.parking}` });
        }
        return info;
    }

    @computed
    get bedsByType(): KVP[] {
        return this.timeline.map(status => status === TimelineStatus.Completed ? { [this.type]: this.beds } : {});
    }

    @computed
    get aptsByType(): KVP[] {
        return this.timeline.map(status => status === TimelineStatus.Completed ? { [this.type]: this.apts } : {});
    }

    @computed
    get classroomsByType(): KVP[] {
        return this.timeline.map(status => status === TimelineStatus.Completed ? { [this.type]: this.classrooms } : {});
    }

    @computed
    get parkingByType(): KVP[] {
        return this.timeline.map(status => status === TimelineStatus.Completed ? { [this.type]: this.parking } : {});
    }

    @computed
    get bedsTotal(): number[] {
        return this.total(() => this.beds);
    }

    @computed
    get aptsTotal(): number[] {
        return this.total(() => this.apts);
    }

    @computed
    get classroomsTotal(): number[] {
        return this.total(() => this.classrooms);
    }

    @computed
    get parkingTotal(): number[] {
        return this.total(() => this.parking);
    }

    total(getter: () => number): number[] {
        const { app } = stores;
        let { endPeriod } = this;
        if (endPeriod < 0) endPeriod = 0;
        return app.periods.map((period: DashiPeriod) => {
            return (period.i === endPeriod) ? getter() : 0;
        })
    }
}


export default FGProject;
