import { stores } from '@strategies/stores';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import StackedAreaChart from '../../../core/ui/charts/StackedAreaChart';
import {DashiChartProps} from "../../../core/ui/charts/Chart";
import MetricView, { KvpMetricView } from "../../../core/models/MetricView";
import Project from "../models/Project";
import ComboChart from "./ComboChart";

@observer
class BedsAreaChart extends ComboChart {

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).bedsTotal);
        makeObservable(this);
    }

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    static get label() {
        return 'Beds';
    }

    static get title() {
        return 'Change in # Beds';
    }

    x = 'Time';
    y = 'Beds';

    get colors() {
        return {
            line: 'rgba(248,54,192,0.5)',
            bar: '#c00f8f',
        };
    }

    static selectedOnLoad = true;
}

export default BedsAreaChart;
