import { stores } from '@strategies/stores';
import {computed, makeObservable, override} from "mobx";
import DashiProject from './Project';
import {IProjectData, TimelineStatus} from "./Project";
import {add, addKVPs, chunk, cumulative, KVP, sumArrays, totalKvp} from "../util";
import {time} from "@strategies/react-timeline";


export class AMetricView {
    constructor() {
        makeObservable(this);
    }

    @computed
    get chartableProjects() {
        const {app} = stores;
        return app.activeBySelection ? app.selectedProjects : app.projects.filter((project: DashiProject) => !project.isExcluded);
    }

    @computed
    get totalsByType(): KVP[] {
        throw new Error('method must be overridden');
    }

    @computed
    get totalsByTypeByYear(): KVP[] {
        return chunk(this.totalsByType, this.periodsPerYear).map(months => months.reduce(addKVPs, {}));
    }

    @computed
    get periodsPerYear(): number {
        const {periodScale} = stores.config;
        return Math.round(time.YEAR / periodScale);
    }

    @computed
    get cumulativeTotalsByTypeByYear(): KVP[] {
        return cumulative(this.totalsByTypeByYear, addKVPs);
    }

    @computed
    get totals(): number[] {
        return this.totalsByType.map(totalKvp);
    }

    @computed
    get totalsClusterYear(): number[] {
        return chunk(this.totals, 12).map(months => months.reduce(add, 0));
    }

    @computed
    get cumulativeTotals(): number[] {
        return cumulative(this.totals, add);
    }

    @computed
    get cumulativeTotalsClusterYear(): number[] {
        return cumulative(this.totalsClusterYear, add);
    }

    @computed
    get current() {
        const {app} = stores;
        return this.totals[app.currentPeriod.i]
    }

    @computed
    get currentByType() {
        const {app} = stores;
        return this.totalsByType[app.currentPeriod.i]
    }

    @computed
    get cumulativeCurrent() {
        const {app} = stores;
        return this.cumulativeTotals[app.currentPeriod.i]
    }
}

export class KvpMetricView extends AMetricView {
    valueGetter: (project: IProjectData) => KVP[];

    constructor(valueGetter: (project: IProjectData) => KVP[]) {
        super();
        makeObservable(this);
        this.valueGetter = valueGetter;
    }

    @override
    get totalsByType(): KVP[] {

        let kvps = sumArrays(this.chartableProjects.map((project: DashiProject) => this.valueGetter(project)), addKVPs);
        return kvps;
    }

}


export default class MetricView extends AMetricView {

    valueGetter: (project: DashiProject) => number[];
    groupGetter?: (project: DashiProject) => string;

    constructor(valueGetter: (project: DashiProject) => number[], groupGetter?: (project: DashiProject) => string) {
        super();
        makeObservable(this);
        this.valueGetter = valueGetter;
        this.groupGetter = groupGetter;
    }

    @computed
    get states(): number[] {
        return this.chartableProjects.map((project: DashiProject) => project.timelineStatus === TimelineStatus.Completed ? 1 : 0)
    }

    @override
    get totals(): number[] {
        const rows = this.chartableProjects.map((project: DashiProject) => this.valueGetter(project))
        const totals: number[] = [];
        if (rows.length === 0) return totals;
        for (let i = 0; i < rows[0].length; i++) {
            let sum = 0;
            for (let j = 0; j < rows.length; j++) {
                sum += rows[j][i];
            }
            totals.push(sum);
        }
        return totals;
    }

    @override
    get totalsByType(): KVP[] {
        const ans: KVP[] = [];
        this.chartableProjects.forEach((project: DashiProject) => {
            const values = this.valueGetter(project);
            const group = this.groupGetter ? this.groupGetter(project) : '';
            for (let i = 0; i < values.length; i++) {
                if (!ans[i]) {
                    ans[i] = {};
                }
                const elem = ans[i];
                if (!elem[group]) {
                    elem[group] = 0;
                }
                elem[group] += values[i];
            }
        })

        return ans;
    }

};
