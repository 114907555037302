import { stores } from '@strategies/stores';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import StackedAreaChart from '../../../core/ui/charts/StackedAreaChart';
import {DashiChartProps} from "../../../core/ui/charts/Chart";
import MetricView, { KvpMetricView } from "../../../core/models/MetricView";
import Project from "../models/Project";
import ComboChart from "./ComboChart";

@observer
class ClassroomsAreaChart extends ComboChart {

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).classroomsTotal);
        makeObservable(this);
    }

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    static selectedOnLoad = true;

    static get label() {
        return 'Classrooms';
    }

    static get title() {
        return 'Change in # Classrooms';
    }

    x = 'Time';
    y = 'Classrooms';

    get colors() {
        return {
            line: 'rgba(6,250,152,0.5)',
            bar: '#059861',
        };
    }
}

export default ClassroomsAreaChart;
