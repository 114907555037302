import { Store, stores } from '@strategies/stores';
import { action, observable, makeObservable } from "mobx";

import { LayoutMode } from './LayoutStore';
import DashiChart from '../ui/charts/Chart';


export default class DashiUIStore extends Store {

    constructor() {
        super();
        makeObservable(this);
    }

    onRegister() {
        stores.app.charts.forEach((chart: typeof DashiChart) => chart.selectedOnLoad && this.setChartSelection(chart, true));
    }

    @observable
    chartsPanelIsOpen: boolean = true;

    @action
    setChartsPanelOpen(isOpen: boolean = true) {
        this.chartsPanelIsOpen = isOpen;
    }

    @observable
    globalSettingsModalIsOpen: boolean = false;

    @action
    setGlobalSettingsModalOpen(isOpen: boolean = true) {
        this.globalSettingsModalIsOpen = isOpen;
    }

    @observable
    fileModalIsOpen: boolean = false;

    @action
    setFileModalOpen(isOpen: boolean = true) {
        this.fileModalIsOpen = isOpen;
    }

    @observable
    infoModalIsOpen: boolean = false;

    @action
    setInfoModalOpen(isOpen: boolean = true) {
        this.infoModalIsOpen = isOpen;
    }

    @observable
    projectSettingsIsOpen: boolean = false;

    @action
    setProjectSettingsOpen(isOpen: boolean = true) {
        this.projectSettingsIsOpen = isOpen;
    }

    @observable
    saveAsModalIsOpen: boolean = false;

    @action
    setSaveAsModalOpen(isOpen: boolean = true) {
        this.saveAsModalIsOpen = isOpen;
    }

    @observable
    settingsDialogIsOpen: boolean = false;

    @action
    setSettingsDialogOpen(isOpen: boolean = true) {
        this.settingsDialogIsOpen = isOpen;
    }

    @observable
    timelinePanelIsOpen: boolean = true;

    @action
    setTimelinePanelOpen(isOpen: boolean = true) {
        if (stores.layout.layoutMode === LayoutMode.NO_COLORIZER) {
            this.timelinePanelIsOpen = true;
        }
        else {
            this.timelinePanelIsOpen = isOpen;
        }
    }

    @observable
    updateScenarioModalIsOpen: boolean = false;

    @action
    setUpdateScenarioModalOpen(isOpen: boolean = true) {
        this.updateScenarioModalIsOpen = isOpen;
    }

    @observable
    welcomeModalIsOpen: boolean = !window.localStorage.getItem('welcomeModalClosed');

    @action
    closeWelcomeModal() {
        this.welcomeModalIsOpen = false;
        window.localStorage.setItem('welcomeModalClosed', 'true');
    }

    @observable
    selectedCharts: string[] = [];

    @action
    setChartSelection(chart: typeof DashiChart, selected: boolean = true) {
        if (selected) {
            this.selectedCharts.push(chart.label);
        }
        else {
            this.selectedCharts.splice(this.selectedCharts.indexOf(chart.label), 1);
        }
    }
    
    isChartSelected(chart: typeof DashiChart) {
        return this.selectedCharts.indexOf(chart.label) !== -1;
    }

}
