const firebaseConfig = {
  apiKey: "AIzaSyDcIyhya5x4mqpt1N1SfrqVTYAGuNQzEVg",
  authDomain: "frederick-gunn-dashi.firebaseapp.com",
  projectId: "frederick-gunn-dashi",
  storageBucket: "frederick-gunn-dashi.appspot.com",
  messagingSenderId: "192754290263",
  appId: "1:192754290263:web:f7066c33f57d7e07a29d12"
};


export default firebaseConfig;
