import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Group, Input, Select } from '@strategies/react-form';

import ProjectSettings from '../../../../core/ui/ProjectSettings';


export default observer(function BeaconProjectSettings() {
    const { app, config } = useStores();
    const { selectedProject } = app;

    if (!selectedProject) {
        return <></>;
    }

    return (
        <ProjectSettings>
            <section>
                <Group>
                    <Select
                        name="Project Type"
                        value={selectedProject.type}
                        options={Object.keys(config.TypeColors)}
                        onChange={value => selectedProject.setType(value)}
                    />

                    <Select
                        name="Use"
                        value={selectedProject.use}
                        options={Object.keys(config.UseColors)}
                        onChange={value => selectedProject.setUse(value)}
                    />
                </Group>
            </section>

            <section className="effects">
                <Group>
                    <Input
                        name="Student Bedcount"
                        type="increment"
                        value={selectedProject.beds}
                        onChange={value => selectedProject.setBeds(parseInt(value))}
                        onBlur={() => isNaN(selectedProject.beds) && selectedProject.setBeds(0)}
                    />

                    <Input
                        name="Faculty Apartments"
                        type="increment"
                        value={selectedProject.apts}
                        onChange={value => selectedProject.setApts(parseInt(value))}
                        onBlur={() => isNaN(selectedProject.apts) && selectedProject.setApts(0)}
                    />

                    <Input
                        name="Classrooms"
                        type="increment"
                        value={selectedProject.classrooms}
                        onChange={value => selectedProject.setClassrooms(parseInt(value))}
                        onBlur={() => isNaN(selectedProject.classrooms) && selectedProject.setClassrooms(0)}
                    />

                    <Input
                        name="Parking"
                        type="increment"
                        value={selectedProject.parking}
                        onChange={value => selectedProject.setParking(parseInt(value))}
                        onBlur={() => isNaN(selectedProject.parking) && selectedProject.setParking(0)}
                    />
                </Group>
            </section>
        </ProjectSettings>
    );
});
