import {makeObservable, override} from 'mobx';

import SummaryChart from '../../../core/ui/charts/SummaryChart';
import {DashiChartProps} from '../../../core/ui/charts/Chart';
import FGProject from '../models/Project'
import MetricView, {KvpMetricView} from "../../../core/models/MetricView";

class Summary extends SummaryChart {

    private bedsView = new MetricView((project) => (project as unknown as FGProject).bedsTotal);
    private aptsView = new MetricView((project) => (project as unknown as FGProject).aptsTotal);
    private classroomsView = new MetricView((project) => (project as unknown as FGProject).classroomsTotal);
    private parkingView = new MetricView((project) => (project as unknown as FGProject).parkingTotal);

    constructor(props: DashiChartProps) {
        super(props);
        makeObservable(this);
    }

    static selectedOnLoad = true;

    @override
    get data() {
        const totalBeds = Math.round(this.bedsView.cumulativeCurrent);
        const totalApts = Math.round(this.aptsView.cumulativeCurrent);
        const totalClassrooms = Math.round(this.classroomsView.cumulativeCurrent);
        const totalParking = Math.round(this.parkingView.cumulativeCurrent);

        return [
            {metric: 'Beds', value: `${totalBeds.toLocaleString()}`},
            {metric: 'Apartments', value: `${totalApts.toLocaleString()}`},
            {metric: 'Classrooms', value: `${totalClassrooms.toLocaleString()}`},
            {metric: 'Parking', value: `${totalParking.toLocaleString()}`},
        ];
    }
}


export default Summary;
