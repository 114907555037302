const FGConfig: any = {

    client: 'The Frederick Gunn School',

    project: 'The Frederick Gunn School',
    contact: 'strategies@sasaki.com',

    userGuide: 'https://docs.google.com/document/d/e/2PACX-1vRnUvHasEt15UVAg6uS9Ns61Db5Ftxy8tbpiUqehSp6ETW-c99K6C3cJ9_b7Ds9j6e8AfheMdsG-Kok/pub',

    colorBy: {
        use: 'UseColors',
        type: 'TypeColors',
    },

    startYear: 2022,
    totalPeriods: (2042 - 2022) * 4,

    TypeColors: {
        'New Build': '#C24DFF',
        'Renovation': '#617DFF',
        'Demo': '#FF002E',
        'Acquire': '#D9A300',
    },

    UseColors: {
        'Academic': '#A88C74',
        'Athletic': '#1F9BF8',
        'Administration': '#91473B',
        'Residential': '#C0008E',
        'Student Life': '#8f8f8f',
        'Faculty Res': '#4FCCCA',
        'Parking': '#FCD21E',
        'Circulation': '#FF7700',
        'Landscape': '#bdc19c',
    },

};

export default FGConfig;
