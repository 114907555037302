import { v4 as uuidv4 } from 'uuid';
import { time } from '@strategies/react-timeline';
import { stores } from '@strategies/stores';
import { ExtendedModel ,model, modelAction } from 'mobx-keystone';

import FGAppStore from '../stores/AppStore';
import DashiPersistStore from '../../../core/stores/PersistStore';

import data from '../assets/projects.json';


@model('fg/PersistStore')
class FGPersistStore extends ExtendedModel(DashiPersistStore, {}) {

    @modelAction
    seed() {
        const { app } = stores;

        this.projects = data.map(r => {
            return (app as FGAppStore).createProject({
                id: uuidv4(),
                timestamps: {
                    start: (6 * time.MONTH),
                    end: (18 * time.MONTH),
                    design: 0,
                },
                ...r
            });
        });
    }

}

export default FGPersistStore;
