import { makeObservable }  from 'mobx';

import DashiUIStore from '../../../core/stores/UIStore';


export default class FGUIStore extends DashiUIStore {

    constructor() {
        super();
        makeObservable(this);
    }

    //selectedCharts: string[] = ['Use & ASF'];

}
