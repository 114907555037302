import { v4 as uuidv4 } from 'uuid';
import { Timespan } from '@strategies/react-timeline';

import AppStore from '../../../core/stores/AppStore';
import FGProject, { IFGProjectData } from '../models/Project';
import SummaryChart from '../charts/Summary';
import BedsAreaChart from "../charts/BedsAreaChart";
import ClassroomsAreaChart from "../charts/ClassroomsAreaChart";
import ParkingAreaChart from "../charts/ParkingAreaChart";
import ApartmentsAreaChart from "../charts/ApartmentsAreaChart";


export default class FGAppStore extends AppStore {

    constructor() {
        super();

        this.colorMode = "use";

        this.charts = [
            SummaryChart,
            BedsAreaChart,
            ApartmentsAreaChart,
            ClassroomsAreaChart,
            ParkingAreaChart,
        ];
    }

    createProjectFromBlock(block: Timespan) {
        return this.createProject({
            id: uuidv4(),
            name: 'New Project',
            mapped: false,
            isExcluded: false,
            timestamps: {
                design: block.start,
                // The default design period length will be 25% of the new block duration.
                start: ((block.end - block.start) * .25) + block.start,
                end: block.end,
            },
            type: 'New Build',
            use: 'Administration',
            beds: 0,
            apts: 0,
            classrooms: 0,
            parking: 0,
        });
    }

    createProject(data: IFGProjectData) {
        return new FGProject(data);
    }

}
