import App from './App';
import './index.scss';
import { bootstrap } from '../../core';
import firebaseConfig from './firebaseConfig';

import config from './config';
import AppStore from './stores/AppStore';
import LayoutStore from './stores/LayoutStore';
import PersistStore from './stores/PersistStore';
import UIStore from './stores/UIStore';

bootstrap(firebaseConfig, config, {
    layout: new LayoutStore(),
    persist: new PersistStore({}),
    app: new AppStore(),
    ui: new UIStore(),
});

export default App;
