import { observer } from 'mobx-react';
import { ReactLogin, SasakiForm, EmailForm } from '@strategies/react-login';
import { useStores } from '@strategies/stores';

import DashiLogo from '../../../core/ui/assets/logo.svg';
import ProjectDataScreen from '../../../core/ui/assets/project-data-screen.png';
import TimelineScreen from '../../../core/ui/assets/timeline-screen.png';
import DataScreen from '../../../core/ui/assets/data-screen.png';


class ClientForm extends EmailForm {
    schema = /^(.+@frederickgunn.org|.+@collierseng.org)$/;
}


export default observer(function Login() {
    const { config } = useStores();

    return (
        <ReactLogin
            logo={DashiLogo}
            title={config.client}
            guide={<>
                <header>User Guide</header>
                <iframe title="User Guide" src={`${config.userGuide}?embedded=true`} />
            </>}
            forms={[
                new ClientForm(config),
                new SasakiForm(config)
            ]}
            screens={[
                { src: TimelineScreen, alt: '', },
                { src: DataScreen, alt: '', },
                { src: ProjectDataScreen, alt: '', },
            ]}
        />
    );
});
