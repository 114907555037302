import { stores } from '@strategies/stores';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import StackedAreaChart from '../../../core/ui/charts/StackedAreaChart';
import {DashiChartProps} from "../../../core/ui/charts/Chart";
import MetricView, { KvpMetricView } from "../../../core/models/MetricView";
import Project from "../models/Project";
import ComboChart, { ComboChartYear } from "./ComboChart";

@observer
class ApartmentsAreaChart extends ComboChart {

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).aptsTotal);
        makeObservable(this);
    }

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    static selectedOnLoad = true;

    static get label() {
        return 'Apartments';
    }

    static get title() {
        return 'Change in # Apartments';
    }

    get colors() {
        return {
            line: 'rgba(177,54,248,0.5)',
            bar: '#790fc0',
        };
    }

    x = 'Time';
    y = 'Apartments';
}

export default ApartmentsAreaChart;
