import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import { Modal, Body, Button } from '@strategies/ui';
import { FiChevronRight } from 'react-icons/fi';

import Logo from '../assets/logo.svg';


export default observer(function WelcomeModal() {
    const { config, ui } = useStores();

    return (
        <Modal
            className='WelcomeModal'
            active={ui.welcomeModalIsOpen}
            onClose={() => ui.closeWelcomeModal()}
        >
            <Body>
                <h1>
                    Welcome to the<br/>
                    {config.client} <br/>
                    Implemention Planning Dashboard
                </h1>

                <img src={Logo} alt="Dashi Logo" title="Dashi by Sasaki" />

                <p>
                    Implementation planning metrics dashboard examining the relationship between analytical and operational aspects.
                </p>

                <Button onClick={() => ui.closeWelcomeModal()}>
                    Go to Dashboard
                    <FiChevronRight />
                </Button>
            </Body>
        </Modal>
    );
});
