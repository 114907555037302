import DashiLayoutStore, { LayoutMode } from '../../../core/stores/LayoutStore';


export default class FGLayoutStore extends DashiLayoutStore {

    constructor() {
        super();

        this.setLayoutOverride(LayoutMode.NO_COLORIZER);
    }

}
