import React from 'react';
import ReactDOM from 'react-dom';

import Skin from './skins/frederick-gunn';


ReactDOM.render(
  <React.StrictMode>
      <Skin />
    </React.StrictMode>,
  document.getElementById('root')
);
