import { stores } from '@strategies/stores';
import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import StackedAreaChart from '../../../core/ui/charts/StackedAreaChart';
import {DashiChartProps} from "../../../core/ui/charts/Chart";
import MetricView, { KvpMetricView } from "../../../core/models/MetricView";
import Project from "../models/Project";
import ComboChart, { ComboChartYear } from "./ComboChart";

@observer
class ParkingAreaChart extends ComboChart {

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as Project).parkingTotal);
        makeObservable(this);
    }

    formatValue(value: number): string {
        return Math.round(value).toLocaleString();
    }

    static get label() {
        return 'Parking';
    }

    static get title() {
        return 'Parking Change';
    }
    y = 'Parking Delta';

    static selectedOnLoad = true;

    get colors() {
        return {
            line: 'rgba(225,139,4,0.5)',
            bar: '#af7402',
        };
    }
}

export default ParkingAreaChart;
