import { SegmentProxy } from '@strategies/react-timeline';
import { override, makeObservable } from 'mobx';

import DashiProject from './Project';


export default class DesignSegmentProxy extends SegmentProxy {

    private readonly project: DashiProject;
    
    constructor(project: DashiProject) {
        super(project.timestamps.start);

        this.project = project;
        makeObservable(this);
    }

    @override
    get value() {
        return this.project.timestamps.start;
    }

    setValue(start: number) {
        this.project.setTimestamps({ start });
    }

    @override
    get color() {
        return '#ccc';
    }

}
