import { FunctionComponent, MouseEvent } from "react";
import { observer } from "mobx-react";

import { noop } from '../../util';


export type CheckboxProps = {
    value: boolean;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Checkbox: FunctionComponent<CheckboxProps> = ({onClick, value}: CheckboxProps) => {
    return (<button className={'Checkbox' + (value ? ' checked' : '')} onClick={(e: MouseEvent<HTMLButtonElement>) => (onClick || noop)(e)}/>)
}

export default observer(Checkbox);
