import { useCallback, useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { FiEdit3, FiTrash } from 'react-icons/fi';
import { Modal, Title, Body, Buttons, Button, IconButton } from '@strategies/ui';


const formatDate = timestamp => {
    const date = new Date(timestamp);

    return `${date.toLocaleString('default', { day: '2-digit'})} ${date.toLocaleString('default', { month: 'short'})} ${date.getFullYear()}`;
};

const FileItem = observer(({ editable = false, file, onDelete }) => {
    const { ui } = useStores();

    return (
        <div className="FileItem">
            <div className="project-info">
                <h4>{file.scenarioName}</h4>
                <p>{file.description}</p>
            </div>

            <div className="project-details">
                <div>
                    <span>Date Created</span> {formatDate(file.created)}
                </div>

                {formatDate(file.created) !== formatDate(file.modified) && (
                    <div>
                        <span>Date Modified</span> {formatDate(file.modified)}
                    </div>
                )}

                <div>
                    <span>Created By</span> {file.createdBy}
                </div>
            </div>

            <div className="project-actions">
                {editable && (
                    <IconButton onClick={() => ui.setUpdateScenarioModalOpen()}>
                        <FiEdit3 />
                    </IconButton>
                )}

                <IconButton onClick={onDelete}>
                    <FiTrash />
                </IconButton>
            </div>
        </div>
    );
});

export default observer(function FileModal() {
    const { persist, ui } = useStores();
    const [files, setFiles] = useState([]);
    const [current, setCurrent] = useState(null);
    const [selected, setSelected] = useState(null);
    const [refreshing, setRefreshing] = useState(true);

    useEffect(() =>  {
        if (ui.fileModalIsOpen) {
            setSelected(null);
            setCurrent(null);
            setRefreshing(true);

            persist.list().then((files = []) => {
                files.forEach(file => {
                    if (file.id === persist.id) {
                        setCurrent(file);
                    }
                });

                setFiles(files);
                setRefreshing(false);
            });
        }
    }, [ui.fileModalIsOpen, persist, persist.scenarioName, persist.description]);

    const onDelete = useCallback((file, e) => {
        e.stopPropagation();

        const _f = [ ...files ];
        _f.splice(files.indexOf(file), 1);
        setFiles(_f);

        persist.delete(file.id);
    }, [files, persist]);

    const load = useCallback(() => {
        persist.setId(selected);
        persist.load();
        ui.setFileModalOpen(false);
    }, [ui, persist, selected]);

    return (
        <Modal
            className='FileModal'
            active={ui.fileModalIsOpen}
            onClose={() => ui.setFileModalOpen(false)}
        >
            <Title>Manage &amp; Load a Scenario</Title>

            <Body>
                {files.length > 0 ? (
                    <>
                        {current && (
                            <div className="current-file">
                                <h3>Current</h3>
                                <FileItem editable={true} file={current} onDelete={e => { onDelete(current, e); setCurrent(null) }} />
                            </div>
                        )}

                        {!(current && files.length === 1) && <>
                            {current && (
                                <h3>Other Scenarios</h3>
                            )}

                            <ul>
                                {files.filter(file => file !== current).map((file, i) => (
                                    <li key={file.id} className={file.id === selected ? 'selected' : ''} onClick={() => setSelected(file.id)}>
                                        <FileItem file={file} onDelete={e => onDelete(file, e, i)} />
                                    </li>
                                ))}
                            </ul>
                        </>}
                    </>
                ) : (
                    refreshing ? (
                        <div className="status loading">
                            <span>Loading scenarios ...</span>
                        </div>
                    ) : (
                        <div className="status blank">
                            <span>No documents saved</span>
                        </div>
                    )
                )}
            </Body>

            <Buttons>
                <Button className="create-fresh secondary" onClick={() => { persist.newDocument(); ui.setFileModalOpen(false)}}>
                    Create Fresh Scenario
                </Button>

                <Button className="secondary" onClick={() => ui.setFileModalOpen(false)}>
                    Cancel
                </Button>

                <Button disabled={!selected} onClick={load}>
                    Load Scenario
                </Button>
            </Buttons>
        </Modal>
    );
});
