import { useStores } from '@strategies/stores';
import { useCallback, useEffect, useState } from 'react';
import { Group, Input, Select } from '@strategies/react-form';
import { time } from '@strategies/react-timeline';
import { observer } from 'mobx-react';
import { FiTrash2 } from 'react-icons/fi';
import { Modal, Title, Body, Buttons, Button, IconButton } from '@strategies/ui';

import { duration } from '../../util';


const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];


export default observer(function ProjectSettings({ title, children }) {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const { app, config, persist, ui } = useStores();
    const { selectedProject: project } = app;

    useEffect(() => setDeleteConfirmation(false), [ui.projectSettingsIsOpen]);

    const cancel = useCallback(() => {
        if (project.snapshots.length > 0) {
            project.undo();
        } else { // fresh project
            persist.remove(project);
        }
        ui.setProjectSettingsOpen(false);
    }, [persist, project, ui]);

    const { design, start, end } = project ? project.timestamps : {};

    return <>
        <Modal
            className="ProjectSettings"
            active={ui.projectSettingsIsOpen && project}
            onClose={() => ui.setProjectSettingsOpen(false)}
        >
            <Title>{title || 'Project Data'}</Title>

            <Body>
                {project && <>
                    <section className="top">
                        <Input
                            name="Project Name"
                            className="project-name"
                            value={project.name}
                            onChange={val => project.setName(val)}
                        />
                    </section>

                    {children}

                    <section className="timestamps">
                        <Group>
                            <Group name="Design Start">
                                <Input className="month" name="Month" htmlFor="design-month" labelPosition='bottom'
                                       value={months[Math.abs(Math.floor(design / time.MONTH)) % 12]}
                                       readonly={true}/>
                                <Input className="year" name="Year" htmlFor="design-year" labelPosition='bottom'
                                       value={Math.floor(design / time.YEAR) + config.startYear} readonly={true}/>
                                <Input className="quarter" name="Quarter" htmlFor="design-quarter"
                                       labelPosition='bottom'
                                       value={`Q${Math.floor((Math.abs(design / time.MONTH) % 12) / 3) + 1}`}
                                       readonly={true}/>
                            </Group>

                            <Input className="duration" name="Duration" htmlFor="design-duration" type="increment"
                                   right="Months" value={duration(design, start)}
                                   onChange={value => project.setTimestamps({design: start - (((isNaN(value) ? 0 : value)) * time.MONTH)})}/>
                        </Group>

                        <Group>
                            <Group name="Construction Start">
                                <Select
                                    className="month"
                                    name="Month"
                                    htmlFor="start-month"
                                    labelPosition='bottom'
                                    options={months}
                                    value={months[Math.abs(Math.floor(start / time.MONTH)) % 12]}
                                    onChange={month => {
                                        const yearStart = start - (start % time.YEAR);
                                        const newStart = yearStart + (months.indexOf(month) * time.MONTH)
                                        const delta = start - newStart;

                                        project.setTimestamps({
                                            design: design - delta,
                                            start: newStart,
                                            end: end - delta,
                                        });
                                    }}
                                />

                                <Input
                                    className="year"
                                    name="Year"
                                    type="increment"
                                    htmlFor="start-year"
                                    labelPosition='bottom'
                                    value={Math.floor(start / time.YEAR) + config.startYear}
                                    onChange={val => {
                                        const years = (val - config.startYear) * time.YEAR;
                                        const months = start % time.YEAR;
                                        const newStart = years + months;
                                        const delta = start - newStart;

                                        project.setTimestamps({
                                            design: design - delta,
                                            start: newStart,
                                            end: end - delta,
                                        });
                                    }}
                                />

                                <Input className="quarter" name="Quarter" htmlFor="start-quarter"
                                       labelPosition='bottom'
                                       value={`Q${Math.floor((Math.abs(start / time.MONTH) % 12) / 3) + 1}`}
                                       readonly={true}/>
                            </Group>

                            <Input className="duration" name="Duration" htmlFor="start-duration" type="increment"
                                   right="Months" value={duration(start, end)}
                                   onChange={value => project.setTimestamps({end: start + (((isNaN(value) ? 0 : value)) * time.MONTH)})}/>
                        </Group>

                        <Group name="Project Completion">
                            <Input className="month" name="Month" htmlFor="end-month" labelPosition='bottom'
                                   value={months[Math.abs(Math.floor(end / time.MONTH)) % 12]} readonly={true}/>
                            <Input className="year" name="Year" htmlFor="end-year" labelPosition='bottom'
                                   value={Math.floor(end / time.YEAR) + config.startYear} readonly={true}/>
                            <Input className="quarter" name="Quarter" htmlFor="end-quarter" labelPosition='bottom'
                                   value={`Q${Math.floor((Math.abs(end / time.MONTH) % 12) / 3) + 1}`}
                                   readonly={true}/>
                        </Group>
                    </section>
                </>}
            </Body>

            <Buttons>
                <IconButton className="delete-project" onClick={() => setDeleteConfirmation(true)}>
                    <FiTrash2 />
                </IconButton>

                <Button className="secondary" onClick={cancel}>Cancel</Button>
                <Button className="primary" onClick={() => ui.setProjectSettingsOpen(false)}>Done</Button>
            </Buttons>
        </Modal>

        <Modal
            className="delete-project-confirmation"
            active={deleteConfirmation}
            onClose={() => setDeleteConfirmation(false)}
        >
            <Title>Delete Project</Title>

            <Body>
                {project && <p>Are you sure you want to delete {project.name}?</p>}
            </Body>

            <Buttons>
                <Button className="secondary" onClick={() => setDeleteConfirmation(false)}>Cancel</Button>

                <Button
                    className="warn"
                    onClick={() => {
                        ui.setProjectSettingsOpen(false);
                        persist.remove(project);
                    }}
                >
                    Delete
                </Button>
            </Buttons>
        </Modal>
    </>;

});
