import { MouseEvent } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { Button } from '@strategies/ui';

import { fixedRectStyle, LayoutMode } from "../../stores/LayoutStore";


type SelectionInfoProps = {
    title: string;
    info: { name: string, value: string }[];
    buttons: { label: string, onClick: (e: MouseEvent<HTMLButtonElement>) => any }[];
};

export default observer(function SelectionInfo({ info, buttons, title }: SelectionInfoProps) {
    const { layout } = useStores();

    const style = layout.layoutMode === LayoutMode.NO_COLORIZER ? {} : fixedRectStyle(layout.selectionInfoRect);

    return (
        <div className="SelectionInfo" style={style}>
            <div className="panel">
                <div className="title-bar">
                    {title}
                </div>

                <div className="content">
                    <div className="info">
                        {info.map(({ name, value }) => (
                            <div key={name} className='row'>
                                <div className='name'>
                                    {name}
                                </div>
                                <div className='value'>
                                    {value}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='buttons'>
                        {buttons.map(({ label, onClick }) =>
                            <Button key={label} className="secondary" onClick={onClick}>
                                {label}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
