import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { Menu, MenuButton, MenuItem, MenuHeader } from "@szhsin/react-menu";
import { FiGlobe, FiList, FiPlus, FiMinus, FiMaximize2 } from 'react-icons/fi';
import { IconButton } from '@strategies/ui';


export default observer(function ToolBar({zoomIn, zoomOut, menuButtons}) {
    const { app, config } = useStores();
    const {ui} = app;

    const togglePanels = () => {
        const bothOpen = !ui.timelinePanelIsOpen && !ui.chartsPanelIsOpen;
        ui.setChartsPanelOpen(bothOpen);
        ui.setTimelinePanelOpen(bothOpen);
    };

    const colorCategories = Object.entries(config[config.colorBy[app.colorMode]]);

    return (
        <div className="ToolBar panel">
            <IconButton onClick={zoomIn}><FiPlus/></IconButton>
            <IconButton onClick={zoomOut}><FiMinus/></IconButton>
            <IconButton onClick={togglePanels}><FiMaximize2/></IconButton>
            {menuButtons.map((menuButton, i) => (
                menuButton.items.length === 1 ?
                    <IconButton onClick={() => menuButton.items[0].onClick()}><FiGlobe/></IconButton> :
                    <Menu
                        key={i}
                        direction={'right'}
                        menuButton={(
                            <MenuButton>
                                <IconButton><FiGlobe/></IconButton>
                            </MenuButton>
                        )}
                    >
                        {menuButton.items.map(item => (
                            <MenuItem key={item.name} onClick={() => item.onClick()}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Menu>
            ))}

            <Menu
                className="no-hover"
                direction={'right'}
                menuButton={(
                    <MenuButton>
                        <IconButton>
                            <FiList/>
                        </IconButton>
                    </MenuButton>
                )}
            >
                {config.legend && Object.entries(config.legend).map(([item, icon]) => (
                    <MenuItem key={item} disabled>
                        <img src={icon} alt={item} /> {item}
                    </MenuItem>
                ))}

                <MenuHeader>{app.colorMode}</MenuHeader>
                {colorCategories.map(([item, backgroundColor]) => (
                    <MenuItem key={item} disabled>
                        <span style={{ backgroundColor }} /> {item}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});
