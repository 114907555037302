import { useState } from 'react';
import { observer } from "mobx-react";
import { useStores } from '@strategies/stores';
import { FiInfo, FiFolder, FiSave } from 'react-icons/fi';
import { TopRibbon, Logo, Title, Drawer, IconButton } from '@strategies/ui';

import DashiLogo from '../assets/logo.svg';


let timeout;

export default observer(function MenuBar() {
    const { config, persist, ui } = useStores();
    const [showSave, setShowSave] = useState(false);
    const [saving, setSaving] = useState(false);

    return (
        <TopRibbon className="MenuBar">
            <Logo><img src={DashiLogo} alt="Dashi Logo" /></Logo>
            <Title>{config.project}</Title>
            <Drawer>
                <IconButton onClick={() => ui.setInfoModalOpen()}>
                    <FiInfo />
                </IconButton>

                <div className="scenario-name-wrapper">
                    <div className="scenario-name">
                        <label htmlFor="scenario">Scenario</label>
                        <input id="scenario" placeholder="Scenario Name ..." value={persist.scenarioName} onChange={v => persist.setScenarioName(v.target.value)} />
                    </div>
                </div>

                <IconButton onClick={() => ui.setFileModalOpen()}>
                    <FiFolder />
                </IconButton>

                <IconButton
                    className="show-save-toggle"
                    onMouseEnter={() => setShowSave(true)}
                    onMouseLeave={() => { clearTimeout(timeout); timeout = setTimeout(() => setShowSave(false), 500)}}
                >
                    <FiSave />
                </IconButton>
            </Drawer>

            <div className={`SaveOptions ${showSave ? 'active' : ''}`}>
                <button
                    className={`primary ${!persist.exists || saving ? 'disabled' : ''}`}
                    disabled={!persist.exists || saving}
                    onClick={() => {
                        setSaving(true);
                        setTimeout(() => setSaving(false), 2000);
                        persist.save()
                    }}
                >
                    {saving ? 'Saving ...' : 'Save'}
                </button>

                <button className="secondary" onClick={() => ui.setSaveAsModalOpen()}>
                    Save As
                </button>
            </div>
        </TopRibbon>
    );
});
